import Vivus from 'vivus';
import Swiper, { Pagination, Navigation } from 'swiper';

Swiper.use([Pagination, Navigation]);

document.addEventListener('DOMContentLoaded', (e) => {

	const blocks = document.querySelectorAll('.block--capabilities');
	const config = { rootMargin: '0px 0px',	threshold: 0 };

	const block_capabilities = {
		block: null,
		swiper: null,
		initialLoad: true,

		init: (block) => {
			block_capabilities.block = block;
			block_capabilities.slider();

			
			// let filters = block.querySelectorAll('.filter');
			// filters.forEach( filter => {
			// 	block_capabilities.filters(filter);
			// })
			
			// let arrow = block.querySelector('.arrow');
			// if (arrow) { block_capabilities.vivus(arrow); }

			// block_capabilities.solutions(block);
			// block_capabilities.filter(block);
			// block_capabilities.reset(block);
		},

		filters: (filter) => {

			let button = filter.querySelector('.dropdown-toggle');
			let input = filter.querySelector('input');
			let options = filter.querySelectorAll('.dropdown-item');

			options.forEach( option => {
				block_capabilities.listener(button, option, input);
			})

		},

		listener: (button, option, input) => {

			let text = option.innerText.toLowerCase();

			option.addEventListener('click', function() {
				button.innerText = text;
				input.value = option.dataset.filter;
				input.dispatchEvent(new Event('change'));
			}, false );

			input.addEventListener('change', function() {
				block_capabilities.solutions(block_capabilities.block);
			});
		},

		filter: (block) => {

			let submit = block.querySelector('.filter-submit');

			submit?.addEventListener('click', function(e) {
				e.preventDefault();
				block_capabilities.solutions(block);
			})
		},
 
		solutions: (block) => {

			let spinner = block.querySelector('.spinner');
			let form = block.querySelector('.solutions-filters form');
			let target = block.querySelector('.solutions-target');
			let clear = block.querySelector('.clear-filters');
			let posts = target.dataset.posts;

			spinner.style.display = 'block';

			clear.classList.add('d-none');

			const data = (form)? new FormData(form) : new FormData();

			data.append('action', 'block_capabilities_get');
			data.append('nonce', block_capabilities_ajax.nonce);
			data.append('url', window.location.href);
			data.append('posts', posts);
			
			fetch ( block_capabilities_ajax.ajax_url, {
				method: 'POST',
				credentials: 'same-origin',
				body: data
			})

			.then( (response) => {

				if (!response.ok) {
					block_capabilities.log('Error getting solutions');
					return;
				}
				return response;
			})
			
			.then( (response) => response.json() )
			
			.then( (data) => {
				spinner.style.display = 'none';

				target.querySelectorAll('.solution').forEach( item => {
					item.classList.remove('active');
				});

				if (target.innerHTML ==='') {
					target.innerHTML = data.response;

				} else if (data.filtered) {

					data.posts.forEach( post => {
						let item = document.getElementById('solution-'+post.ID);						
						if (item) {
							item.classList.add('active');
						}

					});

					clear.classList.remove('d-none');

					if (window.innerWidth < 1200) {
						block_capabilities.scroll();
					}
				}

				if (block_capabilities.initialLoad === true) {
					block_capabilities.slider();
					block_capabilities.initialLoad = false;
				}
			})

		},

		reset: (block) => {

			let filters = block.querySelectorAll('.filter');

			block.addEventListener('click', function(e){
				if ( e.target.classList.contains('clear-filters') ) {
					filters.forEach( filter => {
						filter.querySelector('.dropdown-toggle').innerHTML = '<wbr>';
						filter.querySelector('input').value = '';
					})

					block_capabilities.solutions(block);
					block_capabilities.swiper.slideTo(0);
				}
			})
		},

		vivus: (icon) => {

			let vivus = new Vivus(icon, {
				type: 'oneByOne',
				duration: 100,
				pathTimingFunction: Vivus.EASE_IN,
				onReady: function() {
					icon.style.opacity = 1;
				}
			});
		},

		slider: function() {

			const container = block_capabilities.block.querySelector('.capabilities-posts');
			const wrapper = block_capabilities.block.querySelector('.capabilities-wrapper');
			const slides = wrapper.querySelectorAll('.capability');

			container.classList.add('swiper');
			wrapper.classList.add('swiper-wrapper');
			slides.forEach( slide => {
				slide.classList.add('swiper-slide');
			});

			const nav = document.createElement('div');
			nav.classList.add('swiper-nav');

			container.appendChild(nav);

			const next = document.createElement('div');
			next.classList.add('swiper-button','swiper-button-next');

			next.innerHTML = '<svg width="41" height="23" viewBox="0 0 41 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.8071 11.2454L39.0781 11.2454" stroke="#320FFF" stroke-width="2" stroke-miterlimit="10"/><path d="M7.87464 11.2454L11.666 11.2454" stroke="#320FFF" stroke-width="2" stroke-miterlimit="10"/><path d="M0.997689 11.2454L4.78906 11.2454" stroke="#320FFF" stroke-width="2" stroke-miterlimit="10"/><path d="M27.5739 21.4907L39.0781 11.2454L27.5739 1" stroke="#320FFF" stroke-width="2" stroke-miterlimit="10"/></svg>';

			nav.appendChild(next);

			block_capabilities.swiper = new Swiper('#' + block_capabilities.block.id + ' .swiper', {
				slidesPerView: 1.5,
				spaceBetween: 30,
				slidesOffsetBefore: 12,
				autoHeight: true,
				loop: false,
				roundLengths: true,
				breakpoints: {
					768: {
						slidesPerView: 2.5,
						spaceBetween: 24,
					},
					992: {
						slidesPerView: 3.5,
					},
					1200: {
						slidesPerView: 5,
						simulateTouch: false
					}
				},
				navigation: {
					nextEl: '.swiper-button-next',
				},
			});

		},

		scroll: function() {
			var element = block_capabilities.block.querySelector('.solutions-target');
			var headerOffset = 96;
			var elementPosition = element.getBoundingClientRect().top;
			var offsetPosition = elementPosition + window.scrollY - headerOffset;
		
			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth"
			});

			let active = block_capabilities.block.querySelector('.solution.active');
			if (active) {
				block_capabilities.swiper.slideTo(active.dataset.index);
			}
		},
		
		log: (message) => {
			console.log(message);
		}
	}

	blocks.forEach( block => {
		block_capabilities.init(block);
	});
});